<template>
    <SeoMetaTags />
    <div :data-kontent-item-id="currentPage?.itemid">
        <component :is="activeComponent" />
    </div>
</template>
<script setup lang="ts">
import { InteriorPage, CohortPage, NotFound } from '#components'
import { contentTypes } from '~/models';
const route = useRoute()
const pageStore = usePageStore()
const siteStore = useSiteStore()
const currentPage = siteStore.getPage(route.path, route.query.itemid as string)

const bodyClass = ref<string>('')


const { data, error } = await useAsyncData(`page:${currentPage?.itemid}`, async () => {
    let page = null
    if (currentPage) {
        await pageStore.fetchData(currentPage.itemid)
        page = pageStore.page
    }

    return page
})
if (error.value) {
    console.error(error.value)
}




const activeComponent = resolveComponent(data.value)
useHead(() => {
    return {
        bodyAttrs: {
            class: bodyClass.value
        },
    }
})



function resolveComponent(data: any) {
    bodyClass.value = 'interior-page'
    if (!data) {
        return NotFound
    }

    switch (data?.system?.type) {
        case contentTypes.page.codename:
            return InteriorPage
        case contentTypes._cohort_item_2.codename:
            return CohortPage
        default:
            return NotFound
    }
}


</script>