<template>
  <div class="cohort-page">

    <div class="back-link-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <a href="#" @click="goBack" class="link">
              <img class="icon" src="@/assets/img/icon-chevron-left.svg" />
              Back
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="logo-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <div class="inner-container">
              <div class="logo" v-if="page.elements.logo.value.length > 0">
                <NuxtImg :src="page.elements.logo.value[0]?.url" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cohort-container">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="left-content">
              <h1 class="title">
                {{ page.elements.title.value }}
              </h1>
              <hr />
              <div class="text-group" v-if="page.elements.researchFocus.value.length > 0">
                <h2 class="title">{{ page.elements.researchFocus.value }}</h2>
                <label>Research focus</label>
              </div>
              <div class="text-group" v-if="page.elements.studyType.value.length > 0">
                <h2 class="title">{{ page.elements.studyType.value }}</h2>
                <label>Study type</label>
              </div>
              <div class="text-group" v-if="page.elements.cohortType.value.length > 0">
                <h2 class="title">{{ page.elements.cohortType.value }}</h2>
                <label>Cohort type</label>
              </div>
            </div>
          </div>
          <div class="offset-lg-1 col-lg-5">
            <div class="right-content">
              <div class="text-group" v-if="page.elements.enrollmentDates.value.length > 0">
                <label>Enrollment dates:</label>
                <p class="value">{{ page.elements.enrollmentDates.value }}</p>
              </div>
              <div class="text-group" v-if="page.elements.location.value.length > 0">
                <label>Location:</label>
                <p class="value">{{ page.elements.location.value }}</p>
              </div>
              <div class="text-group" v-if="!isEmpty(page.elements.partnershipsParticipatingInstitutions.value)">
                <label>Partnerships & participating institutions:</label>
                <RichText :value="page.elements.partnershipsParticipatingInstitutions.value" class="value" />
              </div>
              <div class="text-group" v-if="!isEmpty(page.elements.fundingSources.value)">
                <label>Funding sources:</label>
                <RichText :value="page.elements.fundingSources.value" class="value" />
              </div>
              <div class="text-group" v-if="!isEmpty(page.elements.publications.value)">
                <label>Publications:</label>
                <RichText :value="page.elements.publications.value" class="value" />
              </div>
              <div class="text-group" v-if="!isEmpty(page.elements.website.value)">
                <label>Website:</label>
                <RichText :value="page.elements.website.value" class="value" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ContactBlock v-if="contactBlock" :model="contactBlock" />
  </div>
</template>

<script lang="ts" setup>

import { type CohortItem2Model } from '~/models';
import { isEmpty } from '#imports';
const router = useRouter();
const pageStore = usePageStore()
const page = pageStore.page as CohortItem2Model
const contactBlock = await pageStore.fetchContactUsBlock();
function goBack() {
  if (router) {
    router.back();
  }
}

</script>

<style></style>