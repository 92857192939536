<template>
    <div class="interior-page" v-bind:[attributes.itemId]="page.system.id">
        <InteriorHero v-if="page.elements.hero.value.length > 0"
            :model="pageStore.getLinkedItem<InteriorHeroModel>(page.elements.hero.value[0])"
            v-bind:[attributes.elementCodename]="contentTypes.page.elements.content.codename" />
        <RichText :value="page.elements.content.value"
            v-bind:[attributes.elementCodename]="contentTypes.page.elements.content.codename" />
    </div>
</template>
<script setup lang="ts">

import type { InteriorHeroModel, PageModel } from '~/models/content-types';
import { contentTypes } from '~/models';

const attributes = GetKontentAttributes();
const pageStore = usePageStore()
const page = pageStore.page as PageModel

</script>