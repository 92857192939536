<template>
    <section class="interior-hero" v-bind:[attributes.componentId]="type.id" >
        <div class="container">
            <div class="row">
                <div class="col-lg-10">
                    <h1 class="title" v-bind:[attributes.elementCodename]="type.elements.title.codename">
                        {{ title }}
                    </h1>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-9">
                    <h2 class="subtitle" v-bind:[attributes.elementCodename]="type.elements.subtitle.codename" v-if="!isEmpty(subtitle)">
                        {{ subtitle }}
                    </h2>
                    <div class="content" v-bind:[attributes.elementCodename]="type.elements.content.codename" v-if="!isEmpty(content)">
                        <RichText :value="content" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { isEmpty } from '~/utils/kontent-helper';
import { contentTypes, type InteriorHeroModel } from '~/models';

const props = defineProps<{ model: InteriorHeroModel }>();
const title = ref('');
const subtitle = ref('');
const content = ref('');

title.value = props.model.elements.title.value;
subtitle.value = props.model.elements.subtitle.value;
content.value = props.model.elements.content.value;

const attributes = GetKontentAttributes();
const type = contentTypes._interior_hero;
</script>

<style lang="scss" scoped></style>